import https from "./https";
import store from "@/store";

const organization = store.state.liffGeneral.orgCode;

const redeem = {
  getRedeemEvent(params) {
    return https.get(`${organization}/liff/redeem-codes/redeem-event`, { params });
  },

  getRecordByEvent(params) {
    return https.get(`${organization}/liff/redeem-codes/get-record-by-event`, { params });
  },
};

export default redeem;

<template>
  <div class="s-layout" :style="colorObject">
    <Loading
      v-if="isLoading"
      loading-message="載入中..."
      :background-color="'#ffffff'"
    />
    <div class="s-container">
      <div class="page">
        <img src="./images/newyear_header.jpg" class="img-fluid" />
        <img src="./images/newyear_gift.jpg" class="img-fluid" />

        <div class="content">

          <div class="pb-5">
            <img src="./images/newyear_title1.png" class="img-fluid" />

            <div class="d-flex justify-content-center p-3 text-large" v-if="user">
              <div>
                <div class="text-white">
                  <span class="text-highlight mr-2">會員姓名</span>{{ user.name }}
                </div>
                <div class="text-white">
                  <span class="text-highlight mr-2">手機號碼</span>{{ user.mobile_phone }}
                </div>
              </div>
            </div>
          </div>

          <div class="pb-5">
            <img src="./images/newyear_title2.png" class="img-fluid" />

            <div class="d-flex justify-content-center">
              <div class="w-75 p-3">
                <b-form-input
                  v-for="i in 5"
                  :key="i"
                  class="field__input bg-white mb-2 text-uppercase"
                  :placeholder="event.config.input_tip_msg"
                  v-model="redeem_codes[i-1]"
                  :state="isFailedRedeem(redeem_codes[i-1])"
                  :formatter="formatter"
                />

                <div class="d-flex justify-content-center">
                  <label
                    v-if="false"
                    class="agree mt-4 d-flex align-items-center"
                  >
                    <input type="checkbox" class="border-0 mr-2" v-model="checkItem"/>
                    <b-link class="agree__link" v-b-modal.modal-policy>我已同意活動條款及個人資料聲明</b-link>
                  </label>


                  <b-modal dialog-class="modal-policy mx-lg-auto" id="modal-policy" centered hide-footer>
                    <h5 class="modal-title text-center">{{ event.config.T_C_title }}</h5>
                    <div v-html="event.config.T_C_content"></div>
                  </b-modal>
                </div>
              </div>
            </div>

            <button class="d-block" v-if="checkItem">
              <img src="./images/newyear_btn_submit.png" class="img-fluid" @click="redeemCodeAction"/>
            </button>
            <a class="d-block" v-else>
              <img
                src="./images/newyear_btn_submit_disabled.png"
                class="img-fluid"
              />
            </a>

            <b-modal dialog-class="modal-result text-center mx-lg-auto" id="modal-result" centered hide-footer>
              <template #default="{ close }">


                <div v-if="successRedeem.length > 0">
                  <div class="modal-title" v-html="event.config.redeem_msg_success_title.replace('{$redeem_count}', successRedeem.length)"></div>
                  <div class="w-75 modal-result__hr mx-auto my-3"></div>

                  <div v-if="failedRedeem.length==0 && existingRedeem.length==0 && errorMessage.length==0" class="modal-title" v-html="event.config.redeem_msg_success_content"></div>

                </div>

                <div v-if="failedRedeem.length > 0">

                  <div class="modal-title" v-html="event.config.redeem_msg_failed.replace('{$redeem_count}', failedRedeem.length)"></div>
                  <div>
                    <div class="p-1 text-uppercase" v-for="(f,fdx) in failedRedeem" :key="fdx">{{f}}</div>
                  </div>
                </div>

                <div v-if="existingRedeem.length > 0">
                  <div class="modal-title" v-html="event.config.redeem_msg_existing.replace('{$redeem_count}', existingRedeem.length)"></div>
                  <div>
                    <div class="p-1 text-uppercase" v-for="(f,fdx) in existingRedeem" :key="fdx">{{f}}</div>
                  </div>
                </div>

                <div v-if="errorMessage.length > 0">
                  <div v-for="(i,idx) in errorMessage" :key="idx" class="modal-title" >
                    {{ i }}
                  </div>
                </div>

                <b-link @click="close()"  class="d-block mt-3">
                  <img
                    src="./images/newyear_btn_confirm.png"
                    class="img-fluid"
                  />
                </b-link>
            </template>
            </b-modal>

          </div>

          <div v-if="list.length > 0" class="pb-5">
            <img src="./images/newyear_title3.png" class="img-fluid" />

            <div class="reel mt-2">
              <img src="./images/newyear_reel.png" class="img-fluid" />

              <table class="reel__content table table-borderless">
                <thead>
                  <tr class="reel__content__header">
                    <td class="text-center">抽獎序號</td>
                    <td class="text-center">登錄時間</td>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(i, idx) in list" :key="idx">
                    <td class="text-center">{{ i.sn }}</td>
                    <td class="text-center">{{ moment(i.date).format("YYYY-MM-DD HH:mm") }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="reel__content__footer">
                    <td class="text-center">總抽獎次數</td>
                    <td class="text-center">{{ list.length }}</td>
                  </tr>
                </tfoot>
              </table>

              <img
                src="./images/newyear_reel.png"
                class="img-fluid reel__reverse"
              />
            </div>
          </div>

          <div class="text-center p-3 page__footer">
            <div>寶雅國際股份有限公司 版權所有</div>
            <div>© 2025 Poya international Co., Ltd All Rights Reserved.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Packages
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
// import useVuelidate from "@vuelidate/core";
// import { required } from "@vuelidate/validators";
// import _ from "lodash";
// Waltily Utils
import { themeColorFn } from "@/mixins/liff/themeColor";

import dateOptionMixin from "@/mixins/liff/dateOption";

// Api
import redeemApi from "@/apis/liff/v2/redeem";

// Components
import Loading from "@/components/Page/Liff/Shared/Loading";

export default {
  mixins: [themeColorFn({ themeConfigPage: "redeem" }), dateOptionMixin],
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      event: {
        config: {},
      },
      checkItem: true,
      redeem_codes: ["", "", "", "", ""],
      successRedeem: [],
      failedRedeem: [],
      existingRedeem:[],
      errorMessage:[],
      list: [],
      user: {},
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
    }),
    moment() {
      return moment;
    }
  },
  mounted() {
    this.init();
  },

  methods: {
    ...mapActions({
      redeem: 'liffMemberCard/redeem',
      getCard: 'liffMemberCard/getCard',
    }),
    formatter(value) {
      return value.replace(/[^a-zA-Z0-9]/g, '').substring(0, 12).toUpperCase();
    },
    isFailedRedeem(code) {
      if (code) {
        if (this.failedRedeem.includes(code) || this.existingRedeem.includes(code)) {
          return false
        }
        if (this.successRedeem.includes(code)) {
          return true
        }
      } else {
        return null
      }
    },
    async init() {
      this.event_code = this.$route.query.event_code ?? null;


      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId == 'modal-result') {
          this.redeem_codes = ["", "", "", "", ""];
          this.errorMessage = [];
          this.successRedeem = [];
          this.failedRedeem = [];
          this.existingRedeem = [];
        }
      })


      this.fetchMe();
      this.fetchEvent();
      this.fetchRecords();
    },
    async fetchMe() {
      this.user = await this.getCard();
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"]("liff_redeem", key);
    },
    async redeemCodeAction() {
      try {



        if (! this.event_code) {
          this.$swal("錯誤", "你使用的網址錯誤，請重新確認", "warning");
          return;
        }
        const redeemCode = this.redeem_codes.filter((code) => code)

        if (redeemCode.length <= 0) {
          //this.$swal("錯誤", "請輸入刮刮卡上之「抽獎序號」", "warning");

          this.errorMessage.push("請輸入刮刮卡上之「抽獎序號」");
          this.$bvModal.show('modal-result');


          return;
        }



        this.isLoading = true;


        let response = await this.redeem({
          redeem_codes: redeemCode,
          event_code: this.event_code.toLowerCase()
        });

        this.successRedeem = response.success_redeem
        this.failedRedeem = response.failed_redeem
        this.existingRedeem = response.existing_redeem

        this.$bvModal.show('modal-result');

        this.fetchRecords()
      } catch (error) {
        console.error(error);

        if (error.response.message.redeem_codes) {
          this.errorMessage = error.response.message.redeem_codes;

          this.$bvModal.show('modal-result');

          return;

        }

        if (error.response.data.message) {
          return;
        }

        this.$swal("錯誤", "兌換發生錯誤，請稍後再試", "error");
      } finally {
        this.isLoading = false;
      }
    },
    async fetchEvent() {
      try {
        this.isLoading = true;

        const response = await redeemApi.getRedeemEvent({
          event_code: this.event_code,
        });

        this.event = response.data.data.event;
      } catch (error) {
        console.error(error);
        this.$swal('錯誤', '活動資訊讀取失敗', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async fetchRecords() {
      try {
        this.isLoading = true;

        const response = await redeemApi.getRecordByEvent({
          event_code: this.event_code,
        });

        this.list = response.data.data.map((record) => {
          return {
            sn: record.code,
            date: record.redeem_at,
          };
        });
      } catch (error) {
        console.error(error);
        this.$swal('錯誤', '活動資訊讀取失敗', 'error');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";
@import "../../../assets/scss/shared/components/_fields.scss";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
  padding: 0 !important;
}

.s-container {
  min-height: 100vh;
  position: relative;
  max-width: 768px;
  margin: auto;
  padding: 0 !important;
  background-color: #d0425a;
}

.form {
  padding: 0 5px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}

.content {
  background-image: url(./images/newyear_bg.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
}

.page {
  .agree {

    font-size: 14px;

    &__link{
      color: #f5dc7e;
      border-bottom: solid 1px #f5dc7e;
    }
  }

  .reel {
    background-image: url(./images/newyear_reel_bg.png);
    background-repeat: repeat-y;
    background-size: 100%;

    &__content {
      margin: auto;
      width: 83%;
      background: white;
      margin-top: -15px;
      margin-bottom: -15px;

      tbody {
        min-height: 30px;
        max-height: 400px;
        overflow-y: auto;
        display: block;
      }
      thead,
      tfoot,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      thead td,
      tfoot td {
        color: #aa7127;
      }

      thead {
        background-image: url(./images/newyear_reel_header.jpg);
        background-size: 100%;
        background-position: center bottom;
      }

      tfoot {
        background-image: url(./images/newyear_reel_footer.jpg);
        background-size: 100%;
        background-position: center top;
      }
    }
    &__reverse {
      transform: rotate(180deg);
    }

    &__header {
      background-image: url(./images/newyear_reel_header.jpg);
      background-position: center bottom;
      background-size: 100%;
    }

    &__footer {
      background-image: url(./images/newyear_reel_footer.jpg);
      background-position: center top;
      background-size: 100%;
    }
  }

  &__footer {
    font-size: 10px;
    color: white;
  }
}

.text-highlight {
  color: #e9c874;
}

.text-large {
  font-size: 17px;
}

.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}


 ::v-deep .modal{



  .modal-dialog{
    margin: 30px;

    .modal-header{
      padding: .5rem;
      border:none;

      .close{
        padding: .5rem;
        margin: 0;
        background: white;
        border-radius: 100%;
        aspect-ratio: 1;
        line-height: 0;
        color: #B22B3C;
        display: flex;
        align-items: center;
        opacity: 1;
      }
    }

    .modal-title{
      color: #B22B3C;
      font-size: 20px;
    }

    .modal-content{
      background-color: #F5F5F5;
    }


  }


  .modal-policy{
    .modal-body{
      padding: 1rem;
      padding-top: 0;
      font-size: 12px;
    }
  }

  .modal-result{
    .modal-body{
      padding: 1rem;
      padding-top: 0;
      font-size: 20px;
    }

    &__hr{
      background-color: #F7C185;
      height: 2px;
    }
  }


}

::v-deep .modal-backdrop{
  opacity: .5;
}
</style>
